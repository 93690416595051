import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';
import Hero from '../components/hero';
import ArtworkBrowser from '../components/artworkBrowser';

export const query = graphql`{
  allPlayground(
    sort: {fields: openingDate, order: DESC}
    filter: {types: {in: ["Kunst für Landschaft & Garten"]}}
  ) {
    nodes {
      id
      title
      teaser
      slug
      openingDate
      location {
        city
        country
      }
    }
  }
  allFile(filter: {relativeDirectory: {nin: ["", "slides"]}}) {
    nodes {
      id
      name
      relativeDirectory
      childImageSharp {
        gatsbyImageData(width: 300, height: 250, placeholder: NONE, layout: CONSTRAINED)
      }
    }
  }
}
`;

const ArtInNature = ({
  data: {
    allPlayground: { nodes: artworks },
    allFile: { nodes: images },
  },
}) => (
  <Layout>
    <Seo title="Kunst in der Landschaft" />

    <Hero>
      <h1>Kunst in der Landschaft</h1>
      <p className="lead">Gehört zu unseren Lieblingsaufgaben. Hier braucht es ein besonderes Gespür den Platz aufzuwerten und nicht zu zerstören.</p>
      <p>Während Spielplätze in erster Linie in urbaner Gegend zu finden sind, ist die Gestaltung von Erlebniswegen und Kunst in der Landschaft ein überraschender Aspekt auf historische oder natürliche besondere Gegebenheiten hinzuweisen.</p>
    </Hero>

    <div className="container-xl">
      <ArtworkBrowser
        artworks={ artworks }
        images={ images }
        slug='kunst-in-der-landschaft'
      />
    </div>
  </Layout>
);

export default ArtInNature;