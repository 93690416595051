import React from 'react';

import ArtworkCard from '../components/artworkCard';

const ArtworkBrowser = ({ artworks, images, slug }) => (
  <div className="row">
    { artworks.map((artwork) => {
        const cover = images.find(image => image.relativeDirectory === artwork.slug);

        return (
        <div
          className="col-sm-4"
          key={ artwork.id }
        >
          <ArtworkCard
            title={ artwork.title }
            teaser={ artwork.teaser }
            image={ cover }
            location={ artwork.location }
            path={ `/${ slug }/${ artwork.slug }` }
          />
        </div>
      );
    }) }
  </div>
);

export default ArtworkBrowser;
