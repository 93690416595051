import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

const Hero = ({ children, backgroundImage = null }) => (
  <section className="hero">
    <div className="container-xl">
      { children }
    </div>
    { backgroundImage ?
      <GatsbyImage
        image={ backgroundImage }
        alt="Hintergrund Bild"
        className="hero-background-image"
      />: '' }
  </section>
);

export default Hero;