import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const ArtworkCard = ({ title, teaser, image, location, path }) => (
  <Link to={ path } className="artwork-card">
    <article className="card">
      { image
        ? <GatsbyImage
            image={ image.childImageSharp.gatsbyImageData }
            alt={ title }
            className="card-img-top"
          />
        : ''
      }
      <div className="card-body">
        <h4 className="card-title">{ title }</h4>
        <p className="card-text">{ teaser }</p>
      </div>
      <div className="card-footer">
        {
          location.city || location.country
          ? <address>
              <span className="bi-geo-fill"></span>
              { [location.city, location.country].join(', ') }
            </address>
          : ''
        }
      </div>
    </article>
  </Link>
);

export default ArtworkCard;